import { mapActions, mapState } from 'vuex'

export default {
    name: 'ClassScheduleDate',
    data: () => ({
        selectedGroup: null,
        selectedItem: {},
        interval: 30,
        weeks: [],
        panel: 0,
        hours: [],
        firstTime: '07:00',
        intervalCount: 17,
        hourDateSelected: [],
        modalDate: false,
        hourDaysGrid: [],
        loadingSave: false,
        renderComponent: true,
        loadingDelete: false,
        weekdays: [1,2,3,4,5,6],
        loadingGenerateDates: false,
        loadingExportCalendarPdf: false,
        focus: '',
        type: 'week',
        typeToLabel: {
            month: 'Mês',
            week: 'Semana',
            day: 'Dia'
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        editDate: false,
        dateSelected: null,
        timeSelected: null,
        progress: false,
        message: '',
        snackbar: false,
        showChangeDate: false
    }),
    computed: {
        ...mapState('auth', ['acesso', 'user']),
    },
    methods: {
        ...mapActions('hour', ['ActionFindDayOfWeeks', 'ActionGetHours']),
        ...mapActions('group', [ 'ActionIndexGridsDates']),
        ...mapActions('schedule', ['ActionCreateClasse', 'ActionDeleteClasse']),
        ...mapActions('grid', ['ActionGridGenerateDates']),
        
        generateDates(){
          
          let payload = {
            id: this.selectedItem.id,
            date: this.focus
          };

          this.loadingGenerateDates = true;
          
          this.ActionGridGenerateDates(payload)
            .then((res) => {

            }).catch((error) => {

            }).finally(() => {
              this.loadingGenerateDates = false;
            });

        },

        loadClasses(){

          let payload = {
            id: this.selectedItem.group_id,
            grid_id: this.selectedItem.id
          }

          this.ActionIndexGridsDates(payload)
            .then((res) => {
             
              let grid = res.data[0];
              this.selectedItem.classes = grid.classeDates;
              this.selectedItem.grid    = grid;

              this.selectGrid(this.selectedItem, true);
            
             
            }).catch((error) => {

            }).finally(() => {
              
            });

        },
        

        selectGrid(item, force = false){
          
          if(force || !this.selectedGroup || this.selectedGroup.id != item.group_id){
            this.loadEvents(item.parent);
          }
          
          this.selectedGroup  = item.parent;  
          this.selectedItem   = item;

          // this.type = 'month';

          this.events.forEach(ev => {

            let same = ev.grid_id == this.selectedItem.id;
            
            ev.color = same ? 'green darken-1' : 'grey lighten-1';
            ev.actived = same;

          });

          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        },

        getRealHour(hour){

          let find = this.hours.find(h => (h.start.split(':')[0] == hour));

          if(find){
            return find.start + ' - ' + find.end;
          }else{
            return null;
          }

        },

        getWeeks() {
            this.ActionFindDayOfWeeks()
                .then((res) => {
                    this.weeks = res.data
                })
        },
        getHours() {
            
            let payload = {
              find_columns: {
                active: 1
              }
            };
          
            this.ActionGetHours(payload)
              .then((res) => {
                  
                this.hours = res.data;

                if(this.hours.length){
                  
                  let start = this.hours[0].start;
                  let end = this.hours[this.hours.length > 1 ? this.hours.length - 1 : 0].end;
                  
                  let first = start.toString().split(':')[0];
                  let last = end.toString().split(':')[0];
                  
                  this.firstTime = first + ':00';
                  this.intervalCount = (parseInt(last) - parseInt(first)) + 1;
                 
                }


              })
        },
        prev () {
          
          if(this.focus >= this.acesso.periodo.start){
            this.$refs.calendar.prev()
          }
          
        },
        next () {
          
          if(this.focus <= this.acesso.periodo.end){
            this.$refs.calendar.next()
          }
          
        },
        setToday () {
          this.focus = ''
        },

        showEvent ({ nativeEvent, event }) {
          
          // if(event.actived || event.periodicity === 'D') {
          //   this.selectedEvent = event
          //   this.showChangeDate = true
          // }

          const open = () => {
            
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target

            requestAnimationFrame(() => requestAnimationFrame(() => {
              this.selectedOpen = true;
            }));
            
          }
  
          if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
          } else {
            open()
          }
  
          nativeEvent.stopPropagation();

          
          
        },

        destroy(event){
          
          this.loadingDelete = true;

          let payload = {
            id: event.id
          };

          this.ActionDeleteClasse(payload)
          .then((res) => {
              this.loadClasses();
              this.selectedOpen = false;
            
          })
          .catch((error) => {
           
          }).finally(() => {
            this.loadingDelete = false;
            
          })
          
        },

        viewDay ({ date }) {
          this.focus = date;
          this.type = 'day';
        },

        clickTime (tms) {
          
          return;
          
          if(!this.selectedItem || !this.selectedItem.id){
            return;
          }
          
          if(tms.nativeEvent.button !== 0){
            return;
          }

          // const mouse = this.toTime(tms);

          // let start = this.roundTime(mouse);
          // let end = new Date(start + (this.interval * 60000));
          // let dateTimeStart = this.getDateTime(start);
          // let dateTimeEnd = this.getDateTime(end);

          // let find = this.hours.filter(h => (tms.time >= h.start && tms.time <= h.end));

          // if(find && find.length > 0){
          //   this.saveClasse(tms.date, find[0]);
          // }
        },

        roundTime (time, down = true) {
          const roundTo = this.interval; // minutes
          const roundDownTime = roundTo * 60 * 1000;
  
          return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
  
        toTime (tms) {
          return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },

      saveClasse(date, hour)
      {
        
        this.loadingSave = true;
        
        const item = {
          id:   0,  
          name: this.selectedItem.name,
          date: date,
          start: new Date(date + ' ' + hour.start),
          end: new Date(date + ' ' + hour.end),
          timed: true,
          color: 'green lighten-1',
          grid_id: this.selectedItem.id,
          actived: false,
      }
        
        var day = new Date(date);

        const payload = {
            grid_id: this.selectedItem.id,
            work_time_id: this.selectedItem.grid.work_time_id,
            day_of_week_id: (day.getDay() + 2 === 8) ? 1 : day.getDay() + 2,
            hour_id: hour.id,
            teacher_id: this.selectedItem.grid.teacher_id,
            ambience_id: null,
            start_date: date,
            end_date: date,
            locked: 0
        }

        this.ActionCreateClasse(payload)
              .then((res) => {
                  // this.selectedItem.classes = r.data
                  item.id = res.data.id;
                  this.events.push(item)
              })
              .catch((error) => {
                this.progress = false
                if(error.errors && error.errors.class_schedule){
                  this.message = error.errors.class_schedule;
                }else if(error.errors && error.errors.availability){
                  this.message = error.errors.availability;
                }else if(error.message){
                  this.message = error.message;
                }else{
                  this.message = 'Erro ao salvar a aula!';
                }
                this.snackbar = true; 
              }).finally(() => {
                this.loadingSave = false;
              })
      },

      showFormEditDates({ date, time })
      {
        
        this.timeSelected = null;

        if(this.selectedItem && this.selectedItem.id && this.allowEditing(this.selectedItem.grid)){
            this.dateSelected = date;
            this.timeSelected = time;
            this.editDate = true;
        }

      },

      allowEditing(grid)
      {
        return grid.periodicity_type_id == 4 || ([2,3].includes(grid.periodicity_type_id) && !grid.date_mask_type_id);
      },

      loadEvents(group)
      {
        
        this.events = [];
        
        group.children.forEach((grid) => {

          grid.classes.forEach(c => {
            
            const item = {
                id:       c.id,  
                name:     grid.name,
                date:     c.date,
                start:    new Date(c.date + ' ' + c.hour.start),
                end:      new Date(c.date + ' ' + c.hour.end),
                timed:    true,
                color:    'grey lighten-1',
                grid:     grid,
                grid_id:  grid.id,
                actived:  false,
            }

            this.events.push(item);

          });
        
        })
      },

      setScroll(selectedEvent){
        
        let find = selectedEvent.grid.classes.find(c => c.date == selectedEvent.date);
        let index = selectedEvent.grid.classes.indexOf(find);

        let element = document.getElementById('date-item-' + index);
        
        if(element){
            element.scrollIntoView(true);
            this.forceRerender();
        }
        
    },

    forceRerender () {
            
      this.renderComponent = false;

      this.$nextTick(() => {
          this.renderComponent = true;
      });

    },

    },
    created() {
        this.getWeeks()
        this.getHours()
    },
   
}